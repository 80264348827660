<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end" />
    <app-collapse>

      <app-collapse-item
        title="Information générales"
      >

        <!-- form -->

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Gender"
                  label-for="gender"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="gender"
                    rules="required"
                  >
                    <v-select
                      v-model="student.gender"
                      :clearable="false"
                      placeholder="Mademoiselle"
                      label="name"
                      :options="genders"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="CIN ou Passporte"
                  label-for="CIN ou Passporte"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CIN ou Passporte"
                    rules="required"
                  >
                    <b-form-input
                      v-model="student.cin"
                      :state="errors.length > 0 ? false : null"
                      placeholder="CIN ou Passporte"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Nom"
                  label-for="Nom"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nom"
                    rules="required"
                  >
                    <b-form-input
                      v-model="student.first_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Prénom"
                  label-for="Prénom"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Prénom"
                    rules="required"
                  >
                    <b-form-input
                      v-model="student.last_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Prénom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">

                <label for="example-datepicker">Date début de l'année</label>
                <validation-provider
                  #default="{ errors }"
                  name="Date de naissance"
                  rules="required"
                >

                  <flat-pickr
                    v-model="student.date_of_birth"
                    class="form-control invoice-edit-input mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="4">
                <b-form-group
                  label="Lieu"
                  label-for="Lieu"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lieu"
                    rules="required"
                  >
                    <b-form-input
                      v-model="student.place_of_birth"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Lieu"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                  label="Nationalité"
                  label-for="Nationalité"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nationalité"
                    rules="required"
                  >
                    <b-form-input
                      v-model="student.nationality"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nationalité"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

          </b-form>
        </validation-observer>
      </app-collapse-item>

      <app-collapse-item title="Information de contact">
        <b-form>
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Téléphone 1"
                label-for="Téléphone 1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Téléphone 1"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Téléphone 1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Téléphone 2"
                label-for="Téléphone 1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Téléphone 2"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.phone2"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Téléphone 1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Téléphone du Père"
                label-for="Téléphone du Père"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Téléphone du Père"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.fathers_phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Téléphone du Père"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Téléphone du Mère"
                label-for="Téléphone du Mère"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Téléphone du Mère"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.mothers_phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Téléphone du Mère"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Email"
                label-for="Email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Mot de passe"
                label-for="a-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|min:8"
                >
                  <b-form-input
                    id="a-password"
                    v-model="student.password"
                    :state="errors.length > 0 ? false : null"
                    type="password"
                    placeholder="Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Commentaire"
                label-for="Commentaire"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Commentaire"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.comment"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Commentaire"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Parrainage : Cin"
                label-for="Parrainage : Cin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Parrainage : Cin"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.parrainage_cin"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Parrainage : Cin"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Nom"
                label-for="Nom"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nom"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.parrainage_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nom"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Comment avez vous conuu l'université"
                label-for="Comment avez vous conuu l'université"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Comment avez vous conuu l'université"
                  rules="required"
                >
                  <v-select
                    v-model="student.type"
                    :clearable="false"
                    placeholder="Amis"
                    label="name"
                    :options="types"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </app-collapse-item>

      <app-collapse-item title="Information sur l'adresse">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Code Postal en Tunisie"
                label-for="Code Postal en Tunisie"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Code Postal en Tunisie"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.postal_code_tunisia"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Code Postal en Tunisie"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Adresse en Tunisie"
                label-for="Adresse en Tunisie"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Adresse en Tunisie"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.address_tunisia"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Adresse en Tunisie"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Code Postal à l'étranger"
                label-for="Code Postal à l'étranger"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Code Postal à l'étranger"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.postal_code_abroads"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Code Postal à l'étranger"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Adresse à l'étranger"
                label-for="Adresse à l'étranger"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Adresse à l'étranger"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.address_abroad"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Adresse à l'étranger"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </app-collapse-item>

      <app-collapse-item title="Baccalauréat">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Année d'obtention"
                label-for="Année d'obtention"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Année d'obtention"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.bac_year"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Année d'obtention"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Série"
                label-for="Série"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Série"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.bac_series"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Série"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Mention"
                label-for="Mention"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mention"
                  rules="required"
                >
                  <v-select
                    v-model="student.mention_bac"
                    :clearable="false"
                    placeholder="Passable"
                    label="name"
                    :options="mentions_bac"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Lieu"
                label-for="Lieu"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lieu"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.place_of_bac"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Lieu"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Option"
                label-for="option"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Option"
                  rules="required"
                >
                  <v-select
                    v-model="student.option"
                    :clearable="false"
                    placeholder="Mathématiques"
                    label="name"
                    :options="options"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </app-collapse-item>

      <app-collapse-item title="Formation universitaire">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Dérnier diplome"
                label-for="Dérnier diplome"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Dérnier diplome"
                  rules="required"
                >
                  <v-select
                    :clearable="false"
                    placeholder="Bac"
                    label="Dérnier diplome"
                    :options="diplomes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Etablissement"
                label-for="Etablissement"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Etablissement"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.insituation"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Lieu"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Mention"
                label-for="Mention"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mention"
                  rules="required"
                >
                  <v-select
                    v-model="student.mention_university"
                    :clearable="false"
                    placeholder="Passable"
                    label="name"
                    :options="mentions_university"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Année"
                label-for="Année"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Année"
                  rules="required"
                >
                  <b-form-input
                    v-model="student.university_year"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Année"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </app-collapse-item>

    </app-collapse>

    <div class="mt-2">
      <b-button
        variant="primary"
        type="submit"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="addStudent"
      >
        Ajouter
      </b-button>

    </div>

  </div>
</template>
<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    BButton,
    flatPickr,

  },
  data() {
    return {
      password: '',
      types: ['Internet', 'Ancien étudiant', 'Presses', 'Ami', 'Autres'],
      mentions_bac: ['passable', 'Assez-bien', 'Bien', 'Trés bien'],
      mentions_university: ['passable', 'Assez-bien', 'Bien', 'Trés bien'],
      options: ['Sciences expérimentales', 'Sciences techniques', 'Informatique', 'Economie et gestion', 'Lettres', 'Mathématiques', 'Sport', 'Autres'],
      diplomes: ['Bac', 'Licence fondamentale', 'Licence appliquée', 'Maitrise', 'Ingénieur', 'Master', 'Autres'],
      genders: ['Madame', 'Monsieur', 'Mademoiselle'],

      student: {
        role: 'student',
        gender: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        cin: '',
        date_of_birth: '',
        place_of_birth: '',
        nationality: '',
        phone: '',
        phone2: '',
        fathers_phone: '',
        mothers_phone: '',
        comment: '',
        address: '',
        parrainage_cin: '',
        parrainage_name: '',
        postal_code_tunisia: '',
        postal_code_abroad: '',
        address_tunisia: '',
        address_abroad: '',
        bac_year: '',
        bac_series: '',
        mention_bac: '',
        mention_university: '',
        place_of_bac: '',
        option: '',
        diplome: '',
        type: '',
        insituation: '',
        university_year: '',

      },
      required,
    }
  },

  methods: {
    async addStudent() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/authentication/register/',
                {
                  username: `${this.student.first_name} ${this.student.last_name}`,
                  email: this.student.email,
                  cin: this.student.cin,
                  date_of_birth: this.student.date_of_birth,
                  place_of_birth: this.student.place_of_birth,
                  nationality: this.student.nationality,
                  phone: this.student.phone,
                  phone2: this.student.phone2,
                  fathers_phone: this.student.fathers_phone,
                  mothers_phone: this.student.mothers_phone,
                  comment: this.student.comment,
                  address: this.student.address,
                  parrainage_cin: this.student.parrainage_cin,
                  parrainage_name: this.student.parrainage_name,
                  postal_code_abroad: this.student.postal_code_abroad,
                  bac_year: this.student.bac_year,
                  bac_series: this.student.bac_series,
                  mention_bac: this.student.mention_bac,
                  mention_university: this.student.mention_university,
                  place_of_bac: this.student.place_of_bac,
                  option: this.student.option,
                  diplome: this.student.diplome,
                  gender: this.student.gender,
                  role: this.student.role,
                  type: this.student.type,
                  insituation: this.student.insituation,
                  university_year: this.student.university_year,
                  password: this.student.cin,

                },
              )
            this.$router.push('/students')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'information ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        } else {
          this.showToast('danger', 'top-center', 'Information obligatoire !')
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
